import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Authenticator } from '@aws-amplify/ui-react'
import { Hub } from "aws-amplify/utils";
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react-lite';
import authStore from 'root/store/auth-store';

import './login.less'
import '@aws-amplify/ui-react/styles.css';
import image from 'images/hippo-logo.png'
import customTranslations from 'root/auth/amplify/custom-translations';


I18n.putVocabularies(translations);
I18n.putVocabularies(customTranslations);
I18n.setLanguage('fi');


const Header = () => {
    return (
        <div className="signin-header">
            <div className="logo-circle">
                <img className="nav-image" src={image} />
                <div className="hippo-logo">
                    Hippocr<span className="hippo-hl">AI</span>tes
                </div>
            </div>
        </div>
    );
};

const components = {
    SignIn: {
        Header: () => <Header />,
    },
    SignUp: {
        Header: () => <Header />,
    }
};

const formFields = {
    signIn: {
        username: {
            placeholder: "",
        },
        password: {
            placeholder: "",
        }
    },
    signUp: {
        username: {
            placeholder: "",
        },
        password: {
            placeholder: "",
        },
        confirm_password: {
            placeholder: "",
        }
    },
    forgotPassword: {
        username: {
            placeholder: "",
        },
    },
    forceNewPassword: {
        password: {
            placeholder: "",
        },
        confirm_password: {
            placeholder: "",
        }
    }
};

const Login = observer(() => {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";

    useEffect(() => {
        const hubListenerCancel = Hub.listen("auth", (data) => {
            if (data?.payload?.event === "signedIn") {
                authStore.setAuthenticated(true);
            }
        });

        if (authStore.isAuthenticated === null) {
            authStore.checkUserAuth();
        }

        return () => {
            hubListenerCancel();
        };
    }, [navigate, from]);

    if (authStore.isAuthenticated === true) {
        navigate(from, { replace: true });
    }

    return (
        <div className="signin-container">
            <Authenticator components={components} formFields={formFields} />
        </div>
    );
});


export default Login;