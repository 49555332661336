import React from 'react'
import { useTranslation } from 'react-i18next';

import { XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Legend, Bar } from 'recharts';

import './walking-distance.less'

import image from 'images/apple_logo.jpg';

import icon from 'images/icons/steps-icon.svg'

const data = [
    {
        "date": "2024-01",
        "averageDailySteps": 8000,
        "averageDailyCaloriesBurned": 2500
    },
    {
        "date": "2024-02",
        "averageDailySteps": 8200,
        "averageDailyCaloriesBurned": 2550
    },
    {
        "date": "2024-03",
        "averageDailySteps": 8500,
        "averageDailyCaloriesBurned": 2600
    },
    {
        "date": "2024-04",
        "averageDailySteps": 7800,
        "averageDailyCaloriesBurned": 2400
    },
    {
        "date": "2024-05",
        "averageDailySteps": 8100,
        "averageDailyCaloriesBurned": 2450
    },
    {
        "date": "2024-06",
        "averageDailySteps": 8300,
        "averageDailyCaloriesBurned": 2500
    }
]


const CustomToolTip = ({ active, payload, label, locale, t }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="hippo-charts-tooltip">
                <div className="tooltip-header">{`${new Date(label).toLocaleString(locale, { year: 'numeric', month: 'long' })}`}</div>
                <div className="tooltip-content">
                    <div className="average">{`${t('Steps')}: ${payload[0].value}`}</div>
                </div>
            </div>
        )
    }
}

const RenderLineChart = ({ locale, t }: any) => { 
    return (
        <ResponsiveContainer width="92%" height={300}>
            <BarChart data={data}  >
                <XAxis
                    dataKey="date"
                    stroke='#FFFFFF'
                    fontSize={12}
                    tickFormatter={(label) => `${new Date(label).toLocaleString(locale, { month: 'numeric', day: 'numeric' })}`}
                />
                <YAxis stroke='#FFFFFF' />
                <Legend />
                <Bar
                    radius={[6, 6, 0, 0]}
                    legendType={'none'}
                    dataKey="averageDailySteps"
                    fill="#8884d8"
                    maxBarSize={30}
                    activeBar={{ fill: "#00b0b994" }}
                    isAnimationActive={true}
                    animationEasing={'ease-out'}
                    animationDuration={3000}
                />
                <Tooltip content={<CustomToolTip locale={locale} t={t} />} cursor={{ fill: 'none' }} />
            </BarChart>
        </ResponsiveContainer>
    )
}

// TODO: check performance impact of using translate object on each render
export default function WalkingDistanceChart() {
    const { t, i18n } = useTranslation();
    const localeString = i18n.language == 'fi' ? 'fi-FI' : 'en-US'

    return (
        <div className="hippo-module bp-chart-container average-daily-steps-chart">
            <h2>{t('Daily average steps')}</h2>
            <img className="module-icon" src={icon} />
            <RenderLineChart locale={localeString} t={t}/>
            <div className="hippo-source">
                <span className="source-txt"></span>
                <img className="source-logo" src={image} />
            </div>
        </div>
    )
}