import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom";

import './enrich-patient-info.less'


const setData = () => {
    const medicationData = [{
        medicationName: 'Parasetamoli',
        prescriptionDate: '4/2024'
    },
    {
        medicationName: 'Aspiriini',
        prescriptionDate: '4/2024'
    },
    {
        medicationName: 'Bedrocan',
        prescriptionDate: '4/2024'
    }]

    const allergyData = [{
        allergyName: 'Kodeiiniallergia',
        date: '7/2009'
    }, {
        allergyName: 'Penisiliiniallergia',
        date: '1/2022'
    },
    {
        allergyName: 'Aspiriiniallergia',
        date: '5/2024'
    }
    ]

    // used NSCP codes from THL 
    const surgeryData = [{
        surgeryName: 'Sidekalvon korjaaminen CFD10',
        date: '2/2012'
    },
    {
        surgeryName: 'Polven eturistisiteen korjaus NGE30',
        date: '2/2016'
    }]

    return { 
        medicationData,
        allergyData,
        surgeryData
    }
}

const dummyData = setData()

// TODO: translations
export default function EnrichPatientInfo() {

    const navigate = useNavigate()

    const [step, setStep] = useState(0) // first step is 0 

    const [medications, setMedications] = useState([{
        medicationName: '',
        prescriptionDate: '',
    }])

    const [allergies, setAllergies] = useState([{
        allergyName: '',
        date: '',
    }])

    const [surgeries, setSurgeries] = useState([{
        surgeryName: '',
        date: '',
    }])

    const [familyHistory, setfamilyHistory] = useState([{
        entryName: '',
        date: '',
    }])

    useEffect(() => {
        const { medicationData, allergyData, surgeryData } = dummyData
        setMedications(medicationData)
        setAllergies(allergyData)
        setSurgeries(surgeryData)
    })

    const previousStep = () => {
        if (step > 0)
            setStep(step - 1)
    }

    const nextStep = () => {
        if (step < 3)
            setStep(step + 1)
    }


    return (
        <div className="epi-container">
            <div className="epi-header">
                <h2>Lisää potilaan tietoja</h2>
                <button className="epi-button" onClick={() => navigate(-1)}>Valmis</button>
            </div>
            <div className="epi-wizard">
                <div className={`wizard-step ${step === 0 && 'active'}`} onClick={() => setStep(0)}>Lääkitys</div>
                <div className={`wizard-step ${step === 1 && 'active'}`} onClick={() => setStep(1)}>Allergiat</div>
                <div className={`wizard-step ${step === 2 && 'active'}`} onClick={() => setStep(2)}>Leikkaukset</div>
                <div className={`wizard-step ${step === 3 && 'active'}`} onClick={() => setStep(3)}>Sukutiedot ja sairaudet</div>
            </div>
            <div className="epi-navigator">
                <button className="epi-nav-bt" onClick={() => previousStep()}>&lt; Edellinen</button>
                <button className="epi-nav-bt" onClick={() => nextStep()}>Seuraava &gt;</button>
            </div>
            <div className="epi-carousel">
                <div className="epi-medication epi-carousel-item" style={{ transform: `translateX(${step * -100}%)` }}>
                    <div className="epi-list">
                        {medications.map((item, i) =>
                            <div className="epi-list-item" key={i}>
                                <div className="epi-list-medication-name">{item.medicationName}</div>
                                <div className="epi-list-medication-date">{item.prescriptionDate}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="epi-allergies epi-carousel-item" style={{ transform: `translateX(${step * -100}%)` }}>
                    <div className="epi-list">
                        {allergies.map((item, i) =>
                            <div className="epi-list-item" key={i}>
                                <div className="epi-list-medication-name">{item.allergyName}</div>
                                <div className="epi-list-medication-date">{item.date}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="epi-operations epi-carousel-item" style={{ transform: `translateX(${step * -100}%)` }}>
                    <div className="epi-list">
                        {surgeries.map((item, i) =>
                            <div className="epi-list-item" key={i}>
                                <div className="epi-list-medication-name">{item.surgeryName}</div>
                                <div className="epi-list-medication-date">{item.date}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="epi-family-history epi-carousel-item" style={{ transform: `translateX(${step * -100}%)` }}>
                    <div className="epi-list">
                        {familyHistory.length != 1 ? familyHistory.map((item, i) =>
                            <div className="epi-list-item" key={i}>
                                <div className="epi-list-medication-name">{item.date}</div>
                                <div className="epi-list-medication-date">{item.entryName}</div>
                            </div>
                        ) : <div className="epi-list-empty">Ei sukutietoja</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
