import { makeAutoObservable } from "mobx"

class PatientStore {
    // patient data typing?
    patientId: number | null = null;

    constructor() {
        makeAutoObservable(this)
    }

    setPatientId(id: number) {
        this.patientId = id
    }

    getPatientId() {
        return this.patientId
    }

}

const patientStore = new PatientStore()
export default patientStore