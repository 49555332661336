{
    "Dashboard": "Dashboard",
    "Patient search": "Patient search",
    "Sign out": "Sign out",
    "Summary": "Summary",
    "Basic information": "Basic information",
    "Age": "Age",
    "Gender": "Gender",
    "Height": "Height",
    "Weight": "Weight",
    "Body mass index": "Body mass index",
    "Smokes": "Smokes",
    "Chronic diseases": "Chronic diseases",
    "Drug allergies": "Drug allergies",
    "Blood pressure": "Blood pressure",
    "Medication list": "Medication list",
    "Resting heart rate": "Resting heart rate",
    "Daily average steps": "Daily average steps",
    "Patient self-measurements": "Patient self-measurements",
    "Search": "Search",
    "Search for a patient": "Search for a patient",
    "My patients": "My patients",
    "Non-hippo users": "Patients in national repository",
    "Date": "Date",
    "Systolic": "Systolic",
    "Diastolic": "Diastolic",
    "Average": "Average",
    "Steps": "Steps",
    "Lab results": "Lab results",
    "High": "High",
    "Low": "Low",
    "Welcome to Hippo Clinic": "Welcome to Hippo Clinic",
    "Here you will find all relevant information regarding the use of Hippo Clinic": "Here you will find all relevant information regarding the use of Hippo Clinic",
    "Latest": "Latest",
    "Versions & releases": "Versions & releases",
    "Contact us / Give us feedback": "Contact us / Give us feedback",
    "Resources": "Resources",
    "Adding a patient": "Adding a patient",
    "Adding a patient body text": "Patients can be searched through the patient search by their personal identification number, if they have not yet given access to their data. Once a patient has granted read access to their data, they can be added to the \"My Patients\" list, allowing them to view the data.",
    "Rights to read patient data": "Rights to read patient data",
    "Rights to read patient data body text": "Hippo users have their own data vault, which is owned by the patient. The patient independently manages all rights to their own data.",
    "Navigation between patients": "Navigation between patients",
    "Navigation between patients body text": "Patient search allows you to search for patients by name or social security number if access to the patient data has been granted. By clicking on the search result, it is possible to change the patient being processed in the service event.",
    "Modules": "Modules",
    "Modules body text": "Dashboard consists of modules that can be added and removed according to the patient's needs. Modifications are patient-specific and are automatically saved in the system.",
    "Interpreting modules": "Interpreting modules",
    "Interpreting modules body text": "The modules are divided according to subject and use cases. In the bottom right corner of each module, you can see the origin of the data displayed in the module. Details that need special attention are marked in orange. For example, laboratory results exceeding reference values are coloured orange.",
    "Adding and removing modules": "Adding and removing modules",
    "Using charts": "Using charts",
    "Using charts body text": "Modules containing graphs present patient information in a visualised form. Hovering the mouse over the graphs shows additional information about the different data points on the graphs.",
    "Data added by patients": "Data added by patients",
    "Data added by patients body text": "Under the heading \"Patient self-measurements\" contains modules for which the data has not been retrieved from OmaKanta. These modules may include, for example, data collected from smartwatches"
}