import React, { createContext, useContext } from 'react';
import patientStore from 'root/store/patient-store';
import authStore from 'root/store/auth-store';

const StoreContext = createContext({ patientStore, authStore });

export const StoreProvider = ({ children }) => (
    <StoreContext.Provider value={{ patientStore, authStore }}>
        {children}
    </StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);