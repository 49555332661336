import React from 'react'

interface ChevronDownProps {
  strokeColor?: string
  className?: string
  style?: React.CSSProperties
}

const ChevronDown: React.FC<ChevronDownProps> = ({ className, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      style={style}
      stroke="var(--chevron-stroke-color)"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="6 9 12 15 18 9" />
    </svg>
  )
}

export default ChevronDown