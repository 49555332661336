import React from 'react'

import './home.less'

import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Accordion, AccordionItem } from '@szhsin/react-accordion'

import ChevronDown from 'images/components/chevron-down'

const CustomAccordionItem = ({ header, ...rest }) => (
    <AccordionItem
        {...rest}
        header={
            <>
                {header}
                
                <ChevronDown className="chevron-down" /> 
            </>
        }
    />
)

const Home = observer(() => {
    const { t } = useTranslation()

    return (
        <div className="home-container">
            <div className="latest-container">
                <h1>{t('Welcome to Hippo Clinic')}</h1>
                <div>
                    {t('Here you will find all relevant information regarding the use of Hippo Clinic')}
                </div>

                <h2>{t('Latest')}</h2>
                <p>{t('Versions & releases')}</p>
                <p>{t('Contact us / Give us feedback')}</p>
            </div>

            <div className="faq-container">
                <h1>{t('Resources')}</h1>
                <h2>{t('My patients')}</h2>
                <Accordion transition transitionTimeout={250} allowMultiple>
                    <CustomAccordionItem header={t('Adding a patient')}>
                        <div className='accordion-text'>{t('Adding a patient body text')}</div>
                    </CustomAccordionItem>
                    <CustomAccordionItem header={t('Rights to read patient data')}>
                        <div className='accordion-text'>{t('Rights to read patient data body text')}</div>
                    </CustomAccordionItem>
                    <CustomAccordionItem header={t('Navigation between patients')}>
                        <div className='accordion-text'>{t('Navigation between patients body text')}</div>
                    </CustomAccordionItem>
                </Accordion>

                <h2>Dashboard</h2>
                <Accordion transition transitionTimeout={250} allowMultiple>
                    <CustomAccordionItem header={t('Modules')}>
                        <div className='accordion-text'>{t('Modules body text')}</div>
                    </CustomAccordionItem>
                    <CustomAccordionItem header={t('Interpreting modules')}>
                        <div className='accordion-text'>{t('Interpreting modules body text')}</div>
                    </CustomAccordionItem>
                    <CustomAccordionItem header={t('Adding and removing modules')}>
                        <div className='accordion-text'>TBA</div>
                    </CustomAccordionItem>
                    <CustomAccordionItem header={t('Using charts')}>
                        <div className='accordion-text'>{t('Using charts body text')}</div>
                    </CustomAccordionItem>
                    <CustomAccordionItem header={t('Data added by patients')}>
                        <div className='accordion-text'>{t('Data added by patients body text')}</div>
                    </CustomAccordionItem>
                </Accordion>
            </div>
        </div>
    )
})

export default Home