import axios, { AxiosResponse, AxiosError } from 'axios';

// Define types for response and error
type ApiResponse<T> = AxiosResponse<T>;
type ApiError = AxiosError;

// dummy data based on HL7 FHIR patient data

// DATA
import patientData from '../test-data/patient.json'
import diseaseData from '../test-data/chronic-diseases.json'
import summaryData from '../test-data/patient-summary.json'
import allergyData from '../test-data/allergies.json'
import prescriptionData from '../test-data/prescriptions.json'

async function get<T>(url: string): Promise<ApiResponse<T>> {
  try {
    const response = await axios.get<T>(url)
    return response
  } catch (error) {
    throw error
  }
}

async function post<T, U>(url: string, data: T): Promise<ApiResponse<U>> {
  try {
    const response = await axios.post<T, ApiResponse<U>>(url, data)
    return response
  } catch (error) {
    throw error
  }
}

async function put<T, U>(url: string, data: T): Promise<ApiResponse<U>> {
  try {
    const response = await axios.put<T, ApiResponse<U>>(url, data)
    return response
  } catch (error) {
    throw error
  }
}

async function del<T>(url: string): Promise<ApiResponse<T>> {
  try {
    const response = await axios.delete<T>(url)
    return response
  } catch (error) {
    throw error
  }
}

// all following functions are FHIR data accessors for development
export async function getChronicConditions(id: string) {
    const response = await get(`https://2kw2s2rupueijadj4pd4sxursm0kicmm.lambda-url.eu-north-1.on.aws/?id=${id}`)
    return response.data
}

export async function getBasicInfo(id: string) {
    const response = await get(`https://pt4o5rj35sstlspbys3iado3w40rhvig.lambda-url.eu-north-1.on.aws/?id=${id}`)
    return response.data
}

export async function getPrescriptions(id: string) {
    const response = await get (`https://ijqbbpnfmh632uaobv4c6akao40wuyom.lambda-url.eu-north-1.on.aws/?id=${id}`)
    return response.data 
}

export async function getAllergies(id: string) {
    const response = await get(`https://hau3yynjanpwenjaiyitlzcahi0mpfni.lambda-url.eu-north-1.on.aws/?id=${id}`)
    return response.data
}

export async function getSummaryData(id: string) {
    const response = await get(`https://gyakpurh7e24muxrhox2ahwh2m0whicl.lambda-url.eu-north-1.on.aws/?id=${id}`)
    return response.data
}

export async function getOuraData() {
    const response = await get(`https://7mahu5viso4c32uvmhiarldbpu0ovmto.lambda-url.eu-north-1.on.aws/?id=${id}`)
    return response.data
}

export async function searchPatient(id: string) {
  const response = await get(`https://rytphfriasehexaeeo4z4h447y0dqrzb.lambda-url.eu-north-1.on.aws/?ssn=${id}`)
  return response.data
}

export async function getLabResults(id: string) {
  const response = await get(`https://uwtandcpz4tcylqjo56j7itv4e0iensw.lambda-url.eu-north-1.on.aws/?id=${id}`)
  return response.data
}

