import React from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

import './blood-pressure-chart.less'

import image from 'images/kanta_logo.png';

import bpIcon from 'images/icons/blood-pressure-icon.svg'


// Date format: ISO 8601 as per HL7 FHIR (https://build.fhir.org/datatypes.html#dateTime)
const data = [
  { "date": "2024-02-12T08:45", "systolic": 130, "diastolic": 88 },
  { "date": "2024-02-13T14:23", "systolic": 115, "diastolic": 75 },
  { "date": "2024-02-13T09:57", "systolic": 128, "diastolic": 79 },
  { "date": "2024-02-14T16:34", "systolic": 118, "diastolic": 74 },
  { "date": "2024-02-16T11:12", "systolic": 145, "diastolic": 92 },
  { "date": "2024-02-19T07:51", "systolic": 148, "diastolic": 93 },
  { "date": "2024-02-21T19:29", "systolic": 142, "diastolic": 85 }
]

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
}

const CustomLabel = ({ label, locale }: any) => {
  const { i18n } = useTranslation()
  // currently only support for finnish & english
  const localeString = i18n.language == 'fi' ? 'fi-FI' : 'en-US'

  return (
    <div className="bp-chart-custom-label">`${new Date(label).toLocaleString(localeString)}`</div>
  )
}


// TODO: check performance impact of using translate object on each render
const CustomToolTip = ({ active, payload, label, t, locale }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="hippo-charts-tooltip">
        <div className="tooltip-header">{`${new Date(label).toLocaleString(locale, dateOptions)}`}</div>
        <div className="tooltip-content">
          <div className="systolic">{`${t('Systolic')}: ${payload[0].value}`}</div>
          <div className="diastolic">{`${t('Diastolic')}: ${payload[1].value}`}</div>
        </div>
      </div>
    );
  }
}

const RenderLineChart = ({ locale, t }: any) => {

  return (
    <ResponsiveContainer width="88%" height={300} >
      <LineChart className="bp-chart" data={data} margin={{ top: 5, right: 0, bottom: 10, left: -30 }} >
        <Line type="monotone" dataKey="systolic" stroke="#8884d8" strokeWidth={3} />
        <Line type="monotone" dataKey="diastolic" stroke="#00B0B9" strokeWidth={3} />
        <XAxis dataKey="date" fontSize={12} stroke='#ffffff'
          tickFormatter={(label) => `${new Date(label).toLocaleString(locale, { month: 'numeric', day: 'numeric' })}`}
        />
        <YAxis dataKey="systolic" fontSize={12} stroke='#ffffff' />
        <Tooltip content={<CustomToolTip t={t} locale={locale} />} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default function BloodPressureChart() {
  const { t, i18n } = useTranslation()
  const localeString = i18n.language == 'fi' ? 'fi-FI' : 'en-US'

  return (
    <div className="hippo-module bp-chart-container blood-pressure-chart">
      <h2>{t('Blood pressure')}</h2>
      <img className="module-icon" src={bpIcon} />
      <RenderLineChart locale={localeString} t={t} />
      <div className="hippo-source">
        <span className="source-txt"></span>
        <img className="source-logo" src={image} />
      </div>
    </div>
  )
}