import React from 'react'
import { useEffect } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite';
import authStore from 'root/store/auth-store';


const RequireAuth = observer(({ children }: { children: JSX.Element }) => {
    const location = useLocation();

    useEffect(() => {
        authStore.checkUserAuth();
    }, []);

    // Check how this behaves without the following boolean condition, if its fine remove
    // "works" without, but if the user is able to stop code execution before checkUserAuth returns a value,
    // this still returns the content as if the user was authenticated. Could it be exploited? Is there a better way?
    if (authStore.isAuthenticated === null) {
        return <div/>;
    }

    if (!authStore.isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
});

export default RequireAuth;