import { makeAutoObservable } from "mobx";
import { getCurrentUser, signOut as amplifySignOut } from 'aws-amplify/auth';

class AuthStore {
    isAuthenticated: boolean | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async checkUserAuth() {
        try {
            const { username } = await getCurrentUser();
            this.isAuthenticated = !!username;
        } catch (error) {
            console.log(error)
            this.isAuthenticated = false;
        }
    }

    // Check promise objects contents, log out only on success
    // documentation example uses try catch - oskari
    async signOut() {
        try {
            await amplifySignOut();
            this.isAuthenticated = false;
        } catch (error) {
            console.log('error signing out: ', error);
        }
        
    }

    setAuthenticated(status: boolean) {
        this.isAuthenticated = status;
    }
}

const authStore = new AuthStore();
export default authStore;