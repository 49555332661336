import React from 'react'
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import './dashboard.less'

import BasicInfo from 'root/hippo-modules/basic-info/basic-info'
import Allergies from 'root/hippo-modules/allergies/allergies'
import ChronicDiseases from 'root/hippo-modules/chronic-diseases/chronic-diseases'
import Prescriptions from 'root/hippo-modules/prescriptions/prescriptions'
import Summary from 'root/hippo-modules/summary/summary'
import BloodPressureChart from 'root/hippo-modules/blood-pressure-chart/blood-pressure-chart'
import Oura from 'root/hippo-modules/oura/oura-resting-hr'
import WalkingDistanceChart from 'root/hippo-modules/walking-distance/walking-distance'
import LabResults from '../../hippo-modules/lab-results/lab-results';
import { useParams } from 'react-router-dom';

import { useStore } from 'root/store/context/store-context';

const Dashboard = observer(() => {
    // global state with current selected patient needed
    const { t } = useTranslation();
    const { patientStore } = useStore();


    // TODO: safeguards for invalid browser urls/ids 

    const { id } = useParams()
    patientStore.setPatientId(Number(id))
    
    return (
        <div className="dashboard-container">
            <div className="view-container">
                <Summary />
                <LabResults />
                <BasicInfo />
                <ChronicDiseases />
                <Allergies />
                <Prescriptions />
                <BloodPressureChart />        
            </div>
            <h2 className="dashboard-header">{t('Patient self-measurements')}</h2>
            <div className="view-container">
                <Oura />
                <WalkingDistanceChart />
                
            </div>


        </div>
    )
});

export default Dashboard;