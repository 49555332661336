const customTranslations = {
    fi: {
        'Account recovery requires verified contact information':
            'Tilin palautus vaatii vahvistetut yhteystiedot',
        'Add your Profile': 'Lisää profiilisi',
        'Add your Website': 'Lisää verkkosivustosi',
        'Back to Sign In': 'Takaisin kirjautumiseen',
        'Change Password': 'Vaihda salasana',
        Changing: 'Vaihdetaan',
        Code: 'Koodi',
        'Confirm Password': 'Vahvista salasana',
        'Confirm Sign Up': 'Vahvista rekisteröinti',
        'Confirm SMS Code': 'Vahvista SMS-koodi',
        'Confirm MFA Code': 'Vahvista MFA-koodi',
        'Confirm TOTP Code': 'Vahvista TOTP-koodi',
        Confirm: 'Vahvista',
        'Confirmation Code': 'Vahvistuskoodi',
        Confirming: 'Vahvistetaan',
        'Create a new account': 'Luo uusi tili',
        'Create Account': 'Luo tili',
        'Creating Account': 'Luodaan tiliä',
        'Dismiss alert': 'Poista hälytys',
        Email: 'Sähköposti',
        'Enter your Birthdate': 'Syötä syntymäpäiväsi',
        'Enter your code': 'Syötä koodisi',
        'Enter your Confirmation Code': 'Syötä vahvistuskoodisi',
        'Enter your Email': 'Syötä sähköpostiosoitteesi',
        'Enter your Family Name': 'Syötä sukunimesi',
        'Enter your Given Name': 'Syötä etunimesi',
        'Enter your Middle Name': 'Syötä toinen nimesi',
        'Enter your Name': 'Syötä nimesi',
        'Enter your Nickname': 'Syötä lempinimesi',
        'Enter your Password': 'Syötä salasanasi',
        'Enter your phone number': 'Syötä puhelinnumerosi',
        'Enter your Preferred Username': 'Syötä haluamasi käyttäjänimi',
        'Enter your username': 'Syötä käyttäjänimesi',
        'Forgot password?': 'Unohditko salasanan?',
        'Forgot your password?': 'Unohditko salasanasi?',
        'Hide password': 'Piilota salasana',
        'It may take a minute to arrive': 'Tämän saapuminen voi kestää hetken',
        Loading: 'Ladataan',
        'New password': 'Uusi salasana',
        or: 'tai',
        Password: 'Salasana',
        'Phone Number': 'Puhelinnumero',
        'Please confirm your Password': 'Vahvista salasanasi',
        'Resend Code': 'Lähetä koodi uudelleen',
        'Reset your password': 'Nollaa salasanasi',
        'Reset your Password': 'Nollaa salasanasi',
        'Send code': 'Lähetä koodi',
        'Send Code': 'Lähetä koodi',
        Sending: 'Lähetetään',
        'Setup TOTP': 'Määritä TOTP',
        'Show password': 'Näytä salasana',
        'Sign in to your account': 'Kirjaudu tilillesi',
        'Sign In with Amazon': 'Kirjaudu Amazonilla',
        'Sign In with Apple': 'Kirjaudu Applella',
        'Sign In with Facebook': 'Kirjaudu Facebookilla',
        'Sign In with Google': 'Kirjaudu Googlella',
        'Sign in': 'Kirjaudu sisään',
        'Sign In': 'Kirjaudu sisään',
        'Signing in': 'Kirjaudutaan',
        Skip: 'Ohita',
        Submit: 'Lähetä',
        Submitting: 'Lähetetään',
        Username: 'Käyttäjänimi',
        'Verify Contact': 'Vahvista yhteystiedot',
        Verify: 'Vahvista',
        'We Emailed You': 'Lähetimme sinulle sähköpostia',
        'We Sent A Code': 'Lähetimme sinulle koodin',
        'We Texted You': 'Lähetimme sinulle tekstiviestin',
        'Your code is on the way. To log in, enter the code we emailed to':
            'Koodisi on matkalla. Kirjautuaksesi syötä koodi, jonka lähetimme sähköpostiisi',
        'Your code is on the way. To log in, enter the code we sent you':
            'Koodisi on matkalla. Kirjautuaksesi syötä koodi, jonka lähetimme sinulle',
        'Your code is on the way. To log in, enter the code we texted to':
            'Koodisi on matkalla. Kirjautuaksesi syötä koodi, jonka lähetimme tekstiviestinä',
    },
}

export default customTranslations;