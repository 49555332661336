import React from 'react'
import { useTranslation } from 'react-i18next';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import './oura.less'

import image from 'images/oura_logo.png';

import icon from 'images/icons/heart-rate-icon.svg'

const data = [
  {
    "summary_date": "2024-02-01",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-02-02",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-02",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-04-02",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-05-02",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-06-02",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-07-02",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-08-02",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-09-02",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-10-02",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-11-02",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-12-02",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-02-13",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-02-14",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-02-15",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-02-16",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-02-17",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-02-18",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-02-19",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-02-20",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-02-21",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-02-22",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-02-23",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-02-24",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-02-25",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-02-26",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-02-27",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-02-28",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-02-29",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-03-01",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-02",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-03-03",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-03-04",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-05",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-03-06",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-03-07",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-03-08",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-03-09",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-10",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-03-11",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-03-12",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-13",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-03-14",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-03-15",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-03-16",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-17",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-03-18",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-03-19",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-20",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-03-21",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-03-22",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-23",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-03-24",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-03-25",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-03-26",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-03-27",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-03-28",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-03-29",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-03-30",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-03-31",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-04-01",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-04-02",
    "average_resting_heart_rate": 56
  },
  {
    "summary_date": "2024-04-03",
    "average_resting_heart_rate": 54
  },
  {
    "summary_date": "2024-04-04",
    "average_resting_heart_rate": 53
  },
  {
    "summary_date": "2024-04-05",
    "average_resting_heart_rate": 52
  },
  {
    "summary_date": "2024-04-06",
    "average_resting_heart_rate": 55
  },
  {
    "summary_date": "2024-04-07",
    "average_resting_heart_rate": 56
  }
]



// TODO: check performance impact of using translate object on each render
const CustomToolTip = ({ active, payload, label, locale, t }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="hippo-charts-tooltip">
        <div className="tooltip-header">{`${new Date(label).toLocaleString(locale, { year: 'numeric', month: 'numeric', day: 'numeric' })}`}</div>
        <div className="tooltip-content">
          <div className="average">{`${t('Average')}: ${payload[0].value}`}</div>
        </div>
      </div>
    );
  }
}

const RenderLineChart = ({ locale, t }: any) => {
  return (
    <ResponsiveContainer width="90%" height={300}  >
      <LineChart className="" data={data} margin={{ top: 5, right: 0, bottom: 10, left: -30 }}>
        <Line type="monotone"
          dataKey="average_resting_heart_rate"
          stroke="#8884d8"
          dot={false}
          strokeWidth={3} />
        <XAxis
          interval={8}
          dataKey="summary_date"
          fontSize={12}
          stroke='#ffffff'
          tickFormatter={(label) => `${new Date(label).toLocaleString(locale, { month: 'numeric', day: 'numeric' })}`}
        />
        <YAxis
          domain={[44, 60]}
          dataKey="average_resting_heart_rate"
          fontSize={12}
          stroke='#ffffff'
        />
        <Tooltip content={<CustomToolTip locale={locale} t={t} />} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default function Oura() {
  const { t, i18n } = useTranslation();
  const localeString = i18n.language == 'fi' ? 'fi-FI' : 'en-US'

  return (
    <div className="hippo-module bp-chart-container resting-heart-rate-chart">
      <h2>{t('Resting heart rate')}</h2>
      <img className="module-icon" src={icon} />
      <RenderLineChart locale={localeString} t={t} />
      <div className="hippo-source">
        <span className="source-txt"></span>
        <img className="source-logo" src={image} />
      </div>
    </div>
  )
}