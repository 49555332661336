import React from 'react'

import { StoreProvider } from './store/context/store-context'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'

import RequireAuth from './auth/auth'
import { Amplify } from 'aws-amplify'
import amplifyDevConfig from './auth/amplify/config/dev.json'

import Nav from './components/nav/nav'
import Dashboard from './views/dashboard/dashboard'
import EnrichPatientInfo from './views/enrich-patient-info/enrich-patient-info'
import Login from './views/login/login'
import PatientSearch from './views/patient-search/patient-search'
import Home from './views/home/home'

// TODO: automatic configuration for both dev/production, currently uses dev pool 
Amplify.configure(amplifyDevConfig)

export function App() {
  return (
    <StoreProvider>
      <div>
        <Router>
          <Nav />
          <Routes>
            <Route path="/*" element={<Navigate to="/home" />} />
            <Route path="/login" element={<Login />} />
            {ProtectedRoutes()}
          </Routes>
        </Router>
      </div>
    </StoreProvider>
  );
}

function ProtectedRoutes() {
  return (
    <>
      <Route path="/home" element={<RequireAuth><Home /></RequireAuth>} />
      <Route path="/patient-search" element={<RequireAuth><PatientSearch /></RequireAuth>} />
      <Route path="/dashboard/:id" element={<RequireAuth><Dashboard /></RequireAuth>} />
      <Route path="/enrich-patient-info" element={<RequireAuth><EnrichPatientInfo /></RequireAuth>} />
    </>
  )
}